<template>
  <h3 class="subtitle has-text-white">
    Sorry. We couldn't find that Pokémon :(.
  </h3>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
